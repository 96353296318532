/**
 * This is the entry point for Feature Hub App integration
 */

import { ThemeProvider } from '@audi/audi-ui-react';
import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { FootnoteContextProvider, InViewContextProvider } from '@oneaudi/feature-app-utils';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import type { AsyncStateHolder, FeatureServiceDependencies } from '../@types/FeatureAppTypes';
import { createInitialState, deserializeState } from './utils/utils';
import { ContextProvider } from './context/Context';
import AsyncFeatureApp from './FeatureApp';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'audi-market-context-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'onegraph-service': '^1.0.0',
      'audi-footnote-reference-service': '3.0.0',
      'audi-number-formatter-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '^3.x.x',
      '@feature-hub/react': '^3.4.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const {
      's2:logger': s2logger,
      'gfa:locale-service': localeService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'audi-tracking-service': trackingService,
      'audi-content-service': contentService,
      'dbad:audi-i18n-service': i18nService,
      'audi-render-mode-service': renderModeService,
      'onegraph-service': oneGraphService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'audi-number-formatter-service': numberFormatterService,
    } = featureServices;

    if (trackingService) {
      trackingService.featureAppName = 'lean_moxx';
    }

    const logger = s2logger || console;
    logger?.info('Feature App created.');

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await createInitialState(
            oneGraphService,
            localeService,
            i18nService,
            logger,
          );
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = deserializeState(serializedState);
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );

        asyncStateHolder = () =>
          createInitialState(oneGraphService, localeService, i18nService, logger);
      }
    }

    return {
      render: () => {
        return (
          <ThemeProvider>
            <InViewContextProvider logger={logger}>
              <ContextProvider
                featureAppId={featureAppId}
                localeService={localeService}
                logger={logger}
                trackingService={trackingService}
                i18nService={i18nService}
                contentService={contentService}
                renderModeService={renderModeService}
                numberFormatterService={numberFormatterService}
              >
                <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                  <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                    <UniversalEditorProvider
                      contentService={contentService}
                      renderModeService={renderModeService}
                    >
                      <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                    </UniversalEditorProvider>
                  </div>
                </FootnoteContextProvider>
              </ContextProvider>
            </InViewContextProvider>
          </ThemeProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
