// TODO: consider moving into shared package
import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { CarlineStructure } from '../../web/generated/onegraph';
import CarlineStructureQuery from '../../graphql/carlineStructure.graphql';

export const createAsyncCarlineFetch = async (
  oneGraphServiceV1: OneGraphServiceV1,
  { countryCode: country, language }: GfaLocaleServiceV1,
): Promise<CarlineStructure> => {
  const oneGraphClient = oneGraphServiceV1.getApolloClient();

  const {
    data: { carlineStructure },
  } = await oneGraphClient.query<{ carlineStructure: CarlineStructure }>({
    context: {
      consumerId: `${__FEATURE_APP_NAME__}@${__FEATURE_APP_VERSION__}`,
    },
    variables: {
      identifier: {
        country,
        language,
      },
    },
    query: CarlineStructureQuery,
  });
  return carlineStructure;
};
