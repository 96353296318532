import styled from 'styled-components';

const ARROW_BUTTON_WIDTH = '56px';
export const Container = styled.div`
  display: block;
  text-align: center;
  padding-bottom: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    margin-inline: var(${(props) => props.theme.responsive.spacing.pageMargin});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-inline: calc(
      var(${(props) => props.theme.responsive.spacing.pageMargin}) - ${ARROW_BUTTON_WIDTH}
    );

    > *:first-child {
      order: 2;
    }
    > *:nth-child(2) {
      order: 1;
    }
    > *:nth-child(3) {
      order: 3;
    }
  }
`;

export const SliderContainer = styled.ul`
  display: flex;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  scrollbar-width: 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  /* hide scrollbar firefox */
  scrollbar-width: none;
`;

export const CardWrapper = styled.li`
  scroll-snap-align: start;
  overflow: hidden;
  list-style: none;
  min-width: 220px;
  width: 220px;
`;

interface SliderItemProps {
  selected: boolean;
}

export const StyledSliderItem = styled.div<SliderItemProps>`
  padding: var(${(props) => props.theme.responsive.spacing.m});
  flex: 0 0 auto;
  cursor: pointer;
  text-align: left;
  &.selected {
    background-color: var(${(props) => props.theme.colors.base.grey[5]});
  }
  &:hover img {
    transform: scale(1.1);
    transition: transform 0.4s ease;
  }
`;

export const StyledButton = styled('button').withConfig({
  shouldForwardProp: (prop) => !['onPress'].includes(prop),
})`
  background-color: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
`;

export const StyledImage = styled('img')`
  padding-top: var(${(props) => props.theme.responsive.spacing.xs});
  padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
  transition: transform 0.5s;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
