import styled from 'styled-components';

import { injectSpacing, SpacingShorthandList } from '@audi/audi-ui-react';

export const StyledCard = styled.div`
  box-sizing: border-box;
  padding: var(${(props) => props.theme.responsive.spacing.l});
  width: 100%;
  max-width: 332px;
  min-width: 285px;
  background-color: var(${(props) => props.theme.colors.base.brand.white});
`;

export const StyledCardHeader = styled.div`
  height: 84px;
`;

export const StyledCardHeadingContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledCardImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: var(${(props) => props.theme.responsive.spacing.s}) 0;
`;

export const StyledGrid = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
`;

export const StyledText = styled.span`
  margin-bottom: 1px;
`;

export const StyledButtonWrapper = styled.div<{ outerSpacing: SpacingShorthandList }>`
  ${() => injectSpacing('padding')}
`;
