import { Button, Layout } from '@audi/audi-ui-react';
import React from 'react';
import { CardButtonsProps, I18nTexts } from '../../../@types/FeatureAppTypes';
import { useTrackingManager } from '../../context/useTrackingManager';
import { useI18n } from '../../i18n';
import VtpPopover, { StockCar } from './VtpPopover';

const CardButtons = React.memo(
  ({ carlineGroup, carline, isPriceDisplayed, ctaDisplayOptions }: CardButtonsProps) => {
    const availableCarsInStock = carline.availableStock;

    let carsInStock = 0;
    if (availableCarsInStock?.newCars && availableCarsInStock?.newCars?.amount) {
      carsInStock += availableCarsInStock.newCars.amount;
    }
    if (availableCarsInStock?.usedCars && availableCarsInStock.usedCars?.amount) {
      carsInStock += availableCarsInStock.usedCars.amount;
    }

    const newCars = availableCarsInStock?.newCars as StockCar;
    const usedCars = availableCarsInStock?.usedCars as StockCar;

    const { discoverLinkUrl, configureLinkUrl } = carline;

    if (!discoverLinkUrl && !configureLinkUrl && carsInStock === 0) {
      return null;
    }
    const { trackCTAClick } = useTrackingManager();
    const i18nButtonTexts: I18nTexts = {
      discoverCTALabel: useI18n('discoverCTALabel'),
      configureCTALabel: useI18n('configureCTALabel'),
    };
    const useTopPadding =
      !isPriceDisplayed && (discoverLinkUrl || configureLinkUrl || carsInStock > 0);

    return (
      <Layout direction="column" spaceStackStart={useTopPadding ? 'xxs' : 'xs'}>
        {discoverLinkUrl && (
          <Button
            data-testid="discoverButton"
            variant="text"
            size="small"
            spaceStackEnd={!configureLinkUrl && carsInStock === 0 ? undefined : 's'}
            href={discoverLinkUrl}
            onClick={() =>
              trackCTAClick(
                discoverLinkUrl,
                `${carlineGroup.id}~${carline.name}`,
                i18nButtonTexts.discoverCTALabel,
                'discover',
              )
            }
          >
            {i18nButtonTexts.discoverCTALabel}
          </Button>
        )}
        {ctaDisplayOptions.showConfigureCTA && configureLinkUrl && (
          <Button
            data-testid="configureButton"
            variant="text"
            size="small"
            href={configureLinkUrl}
            spaceStackEnd={carsInStock === 0 ? undefined : 's'}
            onClick={() =>
              trackCTAClick(
                configureLinkUrl,
                `${carlineGroup.id}~${carline.name}`,
                i18nButtonTexts.configureCTALabel,
                'configure',
              )
            }
          >
            {i18nButtonTexts.configureCTALabel}
          </Button>
        )}
        {ctaDisplayOptions.showNewAndUsedCarsCTA && carsInStock > 0 && (
          <VtpPopover
            newCars={newCars}
            usedCars={usedCars}
            carsInStock={carsInStock}
            carlineGroup={carlineGroup.id}
            carline={carline.name}
          />
        )}
      </Layout>
    );
  },
);

export default CardButtons;
