import React, { useEffect, useRef, useState } from 'react';
import Card from '../CarLineCard/Card';

import { useInitialState } from '../../context/InitialStateContext';
import { useAppContext } from '../../context/Context';
import { Foldout, StyledCarlineGrid } from './CarlineStyles';

const CarLine: React.FC = () => {
  const { carlineGroups } = useInitialState();
  const { selectedCarGroupId } = useAppContext();
  const { filteredCarlineGroups } = useAppContext();

  const carlineGroupsToDisplay = filteredCarlineGroups || carlineGroups;
  const selectedCarGroup = carlineGroupsToDisplay.find((group) => group.id === selectedCarGroupId);
  const carlines = selectedCarGroup ? selectedCarGroup.carlines : [];

  const [actualHeight, setActualHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateHeight = () => {
      const contentElement = contentRef.current;
      setActualHeight((prevHeight) => {
        const newHeight = contentElement ? contentElement.scrollHeight : 0;
        // Use prevHeight to ensure we're using the previous state
        return newHeight !== prevHeight ? newHeight : prevHeight;
      });
    };
    updateHeight();
    const timeoutId = setTimeout(updateHeight, 100);

    const handleResize = () => {
      clearTimeout(timeoutId);
      setTimeout(updateHeight, 100);
    };
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [selectedCarGroupId, contentRef.current?.scrollHeight]);

  return (
    <Foldout actualHeight={actualHeight}>
      {selectedCarGroup && (
        <StyledCarlineGrid ref={contentRef}>
          {carlines.map((carline, index) => (
            <Card
              key={`${carline.id}-${index}`} // eslint-disable-line react/no-array-index-key
              carline={carline}
              carlineGroup={selectedCarGroup ?? {}}
              lazyImage
            />
          ))}
        </StyledCarlineGrid>
      )}
    </Foldout>
  );
};

export default CarLine;
