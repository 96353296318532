import { LayoutItem, Tag, TagGroup, Text } from '@audi/audi-ui-react';
import { FootnoteReferenceV2 } from '@oneaudi/feature-app-utils';
import React from 'react';
import { getOptimizedImageSrc, getSizes, getSrcSet } from '../../utils/imgUtils';
import type { MoxxCarline, MoxxCarlineGroup } from '../../../@types/MoxxTypes';
import { useAppContext } from '../../context/Context';
import { useInitialState } from '../../context/InitialStateContext';
import { matchI18n, useI18n } from '../../i18n';
import { getStockAmount } from '../../utils/utils';
import { useTrackingManager } from '../../context/useTrackingManager';

import CardButtons from './CardButtons';
import ConsumptionAndEmission from './ConsumptionAndEmission';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeadingContainer,
  StyledCardImg,
  StyledGrid,
  StyledText,
} from './CardStyles';
import {
  CARGROUP_IMAGE_SIZE_DESKTOP,
  CARGROUP_IMAGE_SIZE_MOBILE,
  CARLINE_IMG_SIZE_DESKTOP,
  CARLINE_IMG_SIZE_MOBILE,
  IMAGE_MIME_TYPE,
} from '../../constants';

export interface CardProps {
  readonly carline: MoxxCarline;
  readonly carlineGroup: MoxxCarlineGroup;
  readonly lazyImage: boolean;
}

const Card: React.FC<CardProps> = ({ carline, carlineGroup, lazyImage }) => {
  const { i18nMessages } = useInitialState();
  const {
    priceFootnotes,
    showBasePrice,
    showCaeData,
    showConfigureCTA,
    numberFormatterService,
    showNewAndUsedCarsCTA,
  } = useAppContext();
  const electricTag = useI18n('electricTag');
  const hybridTag = useI18n('hybridTag');
  const carsInStock = getStockAmount(carline);
  const { trackCarlineImageClick } = useTrackingManager();
  const cardImageSrc = getOptimizedImageSrc(carline.image, {
    extension: IMAGE_MIME_TYPE,
    width: CARLINE_IMG_SIZE_DESKTOP,
  });

  const newLocal = CARLINE_IMG_SIZE_MOBILE * 2;

  const handleCardImageClick = (carlineGroupID: string, carlineName: string) => {
    // Special case -> data team wants to know how often the image is clicked
    trackCarlineImageClick(`${carlineGroupID}~${carlineName}`);
  };

  return (
    <StyledCard data-testid="card" data-carlineid={carline.id} data-configurator={!carline.isFake}>
      <StyledCardHeader>
        <StyledCardHeadingContainer>
          <Text variant="order4" data-testid="carlineName">
            {carline.name}
          </Text>
        </StyledCardHeadingContainer>
        {carline.vehicleType === 'PHEV' || carline.vehicleType === 'BEV'}
        <TagGroup spaceStackStart="xs">
          {carline.vehicleType === 'PHEV' && (
            <Tag data-testid="hybridTag" label={hybridTag} size="small" role="listitem" />
          )}
          {carline.vehicleType === 'BEV' && (
            <Tag data-testid="electroTag" label={electricTag} size="small" role="listitem" />
          )}
        </TagGroup>
      </StyledCardHeader>
      {cardImageSrc && (
        <StyledCardImg
          src={cardImageSrc}
          srcSet={getSrcSet(
            [
              CARLINE_IMG_SIZE_DESKTOP,
              CARLINE_IMG_SIZE_DESKTOP * 2,
              CARLINE_IMG_SIZE_MOBILE,
              newLocal,
            ].map((width) => {
              return {
                src: getOptimizedImageSrc(cardImageSrc, { width }),
                width: `${width}w`,
              };
            }),
          )}
          sizes={getSizes([
            { min: 'm', width: `${CARGROUP_IMAGE_SIZE_DESKTOP}px` },
            { width: `${CARGROUP_IMAGE_SIZE_MOBILE}px` },
          ])}
          loading={lazyImage ? 'lazy' : undefined}
          onClick={() =>
            // special case -> data team wants to know how often the image is clicked
            handleCardImageClick(carlineGroup.id, carline.name)
          }
        />
      )}
      {showBasePrice && carline.minPrice?.value && !carline.isFake && (
        <StyledGrid>
          <Text variant="copy1" data-testid="price" spaceStackEnd="xxs">
            {matchI18n('basePriceText', i18nMessages, {
              value: numberFormatterService.formatCurrency(carline.minPrice.value),
            })}
            {priceFootnotes?.map((footnote: string, i: number) => (
              <React.Fragment key={footnote}>
                {i > 0 && <sup>{', '}</sup>}
                <FootnoteReferenceV2 footnoteId={footnote} data-testid={`price-footnote_${i}`} />
              </React.Fragment>
            ))}
          </Text>
          <Text variant="copy2" spaceStackStart={priceFootnotes?.length !== 0 ? 'xxs' : undefined}>
            <StyledText>{matchI18n('vat', i18nMessages)}</StyledText>
          </Text>
        </StyledGrid>
      )}

      <CardButtons
        carline={carline}
        carlineGroup={carlineGroup}
        isPriceDisplayed={
          showBasePrice && !!(carline.minPrice?.value && carline.minPrice.value > 0)
        }
        ctaDisplayOptions={{ showConfigureCTA, showNewAndUsedCarsCTA }}
      />
      {showCaeData && carline.consumptionAndEmission && (
        <LayoutItem
          spaceStackStart={
            !carline.configureLinkUrl && !carline.discoverLinkUrl && carsInStock === 0
              ? undefined
              : 's'
          }
          data-testid="consumptionAndEmission"
        >
          <ConsumptionAndEmission
            consumptionData={carline.consumptionAndEmission}
            carlineName={carline.name}
          />
        </LayoutItem>
      )}
    </StyledCard>
  );
};

export default Card;
