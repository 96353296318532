import React from 'react';
import { Button, ButtonProps } from '@audi/audi-ui-react';
import styled from 'styled-components';

interface ArrowButtonProps extends ButtonProps {
  direction: 'left' | 'right'; // Define the direction prop as either 'left' or 'right'
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, onClick, disabled, variant }) => {
  return (
    <StyledButton
      disabled={disabled}
      data-testid={`arrow-button-${direction}`}
      type="button"
      variant={variant}
      icon={direction === 'left' ? 'back' : 'forward'}
      onClick={onClick}
    />
  );
};

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    background: none;
    border: none;
    box-shadow: none;
    display: block;
  }
`;

export default ArrowButton;
