import React from 'react';
import styled from 'styled-components';
import { useInViewEffect } from '@oneaudi/feature-app-utils';
import CarGroup from '../CarGroup/CarGroup';
import Carline from '../CarLine/Carline';
import { Filter } from '../Filter/Filter';
import { useInitialState } from '../../context/InitialStateContext';
import { useTrackingManager } from '../../context/useTrackingManager';

const LeanMoxxView: React.FC = () => {
  const { carlineGroups } = useInitialState();
  const trackingManager = useTrackingManager();

  useInViewEffect(() => {
    if (carlineGroups) {
      trackingManager.impression();
    }
  });
  return (
    <Layout>
      <Filter />
      <CarGroup />
      <Carline />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(${({ theme }) => theme.responsive.spacing.xxxl}) auto;
`;

export default LeanMoxxView;
