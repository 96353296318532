export const checkLinkTarget = (url: string) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;

  if (tempLink.hostname === window.location.hostname) {
    return 'internal_link';
  }
  return 'exit_link';
};

export const completeTargetURL = (targetURL: string) => {
  const { host } = document.location;
  if (targetURL.startsWith('/')) {
    return `${host}${targetURL}`;
  }
  return targetURL;
};
