/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Text, Icon, Select } from '@audi/audi-ui-react';
import {
  FilterWrapper,
  MobileFilterContainer,
  TagGroupContainer,
  ToggleTagsContainer,
  TagContainer,
  ContentWrapper,
  StyledText,
} from './FilterStyles';
import { useInitialState } from '../../context/InitialStateContext';
import { useAppContext } from '../../context/Context';
import { MoxxCarlineGroup } from '../../../@types/MoxxTypes';
import { useTrackingManager } from '../../context/useTrackingManager';

export const Filter = () => {
  const { bodyTypes, i18nMessages } = useInitialState();
  const { trackTagClick, trackExpandTagsClick } = useTrackingManager();
  const {
    selectedBodyType,
    setSelectedBodyType,
    setFilteredCarlineGroups,
    setSelectedCarGroupId,
    logger,
  } = useAppContext();
  const [showMore, setShowMore] = useState(false);
  const { carlineGroups: unfilteredCarlineGroups } = useInitialState();

  const [screenWidth, setScreenWidth] = useState<number | null>(null);
  const [visibleItems, setVisibleItems] = useState<[string, string][]>([]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const calculateVisibleItems = () => {
      if (showMore) {
        setVisibleItems(bodyTypes);
      } else {
        if (screenWidth === null) {
          return;
        }
        if (screenWidth >= 1024 && screenWidth < 1440) {
          setVisibleItems(bodyTypes.slice(0, 4));
        } else if (screenWidth >= 1440 && screenWidth < 1920) {
          setVisibleItems(bodyTypes.slice(0, 6));
        } else {
          setVisibleItems(bodyTypes.slice(0, 10));
        }
      }
    };

    calculateVisibleItems();
  }, [bodyTypes, showMore, screenWidth]);

  const truncateString = (inputString: string | undefined): string => {
    const maxLength = 20;
    if (!inputString) return '';

    if (inputString.length <= maxLength) {
      return inputString;
    }
    return `${inputString.slice(0, maxLength - 3)}...`;
  };

  const handleOnChange = (value: string | undefined) => {
    if (setSelectedBodyType && setSelectedCarGroupId) {
      setSelectedCarGroupId(undefined);
      setSelectedBodyType((prevSelected) => {
        return prevSelected === value ? undefined : value;
      });
    } else {
      logger?.warn('setSelectedBodyType is not defined');
    }
  };

  const filterCarGroupsByBodyType = () => {
    if (!setFilteredCarlineGroups) {
      return;
    }

    let filteredGroups: MoxxCarlineGroup[];

    if (selectedBodyType) {
      // Filter car groups based on selected bodyType
      filteredGroups = unfilteredCarlineGroups
        .map((carGroup) => {
          const matchingCarlines = carGroup.carlines.filter((carline) =>
            carline.categories.includes(selectedBodyType),
          );
          if (matchingCarlines.length > 0) {
            return {
              ...carGroup,
              carlines: matchingCarlines,
            };
          }
          return null;
        })
        .filter((group): group is MoxxCarlineGroup => group !== null);
    } else {
      // If selectedBodyType is undefined, show all carlineGroups
      filteredGroups = unfilteredCarlineGroups;
    }

    setFilteredCarlineGroups(filteredGroups);
  };

  useEffect(() => {
    filterCarGroupsByBodyType();
  }, [selectedBodyType]);

  return (
    <FilterWrapper>
      <MobileFilterContainer>
        <Select
          inputId="bodyTypeFilter"
          label={i18nMessages.allModelsFilterText}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const { selectedIndex } = e.target;
            const selectedOption = e.target.options[selectedIndex];
            const selectedText = selectedOption.text;
            const selectedValue = selectedOption.value;

            handleOnChange(selectedValue);
            trackTagClick(selectedText, selectedValue);
            setShowMore(true);
          }}
          value={selectedBodyType}
          hideLabelOptional
        >
          {bodyTypes.map((bodyType, index) => (
            <option value={bodyType[0]} key={`${bodyType[0]}-${index}`}>
              {bodyType[1]}
            </option>
          ))}
        </Select>
      </MobileFilterContainer>
      <TagGroupContainer>
        {/* initialy selected default tag */}
        <TagContainer
          data-testid="allModelsTag"
          role="button"
          tabIndex={0}
          onClick={() => handleOnChange(undefined)}
          className={`${!selectedBodyType ? 'active' : ''}`}
        >
          <ContentWrapper>
            {!selectedBodyType && <Icon name="select" size="small" />}
            <Text variant="copy1">{truncateString(i18nMessages.allModelsFilterText)}</Text>
          </ContentWrapper>
        </TagContainer>

        {visibleItems.map((bodyType, index) => (
          <TagContainer
            key={`${bodyType[0]}-${index}`}
            data-testid={bodyType[0]}
            role="button"
            tabIndex={0}
            onClick={() => {
              handleOnChange(bodyType[0]);
              trackTagClick(bodyType[1], bodyType[0]);
            }}
            className={`${selectedBodyType === bodyType[0] ? 'active' : ''}`}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                handleOnChange(bodyType[0]);
                trackTagClick(bodyType[1], bodyType[0]);
              }
            }}
          >
            <ContentWrapper>
              {selectedBodyType === bodyType[0] && <Icon name="select" size="small" />}
              <Text variant="copy1">{bodyType[1]}</Text>
            </ContentWrapper>
          </TagContainer>
        ))}
        {!showMore && (
          <ToggleTagsContainer
            role="button"
            data-testid="showMoreTag"
            tabIndex={0}
            onClick={() => {
              setShowMore(true);
              trackExpandTagsClick(i18nMessages.showMoreText);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                setShowMore(true);
                trackExpandTagsClick(i18nMessages.showMoreText);
              }
            }}
          >
            <Text variant="copy1" weight="bold" data-testid="showMoreButton">
              <StyledText>{truncateString(i18nMessages.showMoreText)}</StyledText>
            </Text>
          </ToggleTagsContainer>
        )}
      </TagGroupContainer>
    </FilterWrapper>
  );
};
