import * as React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  transition: transform 200ms ${(props) => props.theme.easing};
  margin-inline-start: var(${(props) => props.theme.responsive.spacing.s});
  transform: rotateZ(0deg);

  &.rotated-90 {
    transform: rotateZ(90deg);
  }

  &.rotated-180 {
    transform: rotateZ(-180deg);
  }
`;

export interface ArrowIconProps {
  readonly rotated: boolean;
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <StyledIcon
    className={rotated ? `rotated-180` : ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10L12.5 15.5 18 10"
      stroke="#333333"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </StyledIcon>
);
