export function getKeyboardFocusableElements<Type extends HTMLElement>(element: Type) {
  const elements = element.querySelectorAll(
    `a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])`,
  );
  return Array.from(elements).filter((el) => !el.hasAttribute('disabled'));
}

export const createVisibilityChecker = (containerElement: HTMLElement, el: HTMLElement | null) => {
  if (el) {
    const rect = containerElement.getBoundingClientRect();
    const { left, right } = el.getBoundingClientRect();
    return Math.round(left) >= Math.round(rect.left) && Math.round(right) <= Math.round(rect.right);
  }
  return false;
};
