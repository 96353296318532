import React from 'react';
import { TrackingDataV2 } from '@oneaudi/tracking-service';
import { useLogger, useTrackingService, useAppContext } from './Context';
import { checkLinkTarget, completeTargetURL } from '../utils/trackingUtils';

export interface CtaTexts {
  primary: string;
  secondary: string;
}

export interface AppliedFilter {
  id: string;
  values: string[] | number[];
}

export interface TrackingManager {
  ready(version: string): void;
  impression(): void;
  click(
    targetURL: string,
    label: string,
    linkValue: string,
    carlineGroup: string,
    carline: string,
    amount?: number,
  ): void;
  trackTagClick(label: string, value: string): void;
  trackExpandTagsClick(value: string): void;
  trackExpandCardClick(value: string, id: string, element: string): void;
  trackExpandVTPLayerClick(value: string, label: string): void;
  trackCTAClick(
    targetURL: string,
    label: string,
    ctaType: string,
    value: string,
    amount?: number,
    elementName?: 'tag' | 'text link' | 'image' | 'icon' | 'other',
  ): void;
  trackCarlineImageClick(value: string): void;
  trackScrollOrSwipeEvent(): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
  componentUpdate: {
    componentInfo: {
      implementer: number;
      version: string;
    };
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingDataV2 {
  const defaultAttributes = {
    componentName: 'lean-moxx',
    label: '',
    targetURL: '',
    clickID: '',
    value: '',
    pos: '',
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const logger = useLogger();
  const trackingService = useTrackingService();
  const { selectedCarGroupId } = useAppContext();

  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) logger.debug('Sending tracking event: ', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        const componentPayload = createComponentPayload(version);

        logger?.debug('Sending tracking event: ', {
          ...payload,
          datalayerAdditions: componentPayload,
        });

        const x: TrackingDataV2 = {
          ...payload,
          componentUpdate: componentPayload,
        };
        trackingService.track(x);
      }
    }

    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'lean moxx - feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },
      impression() {
        track('impression', `lean moxx - impression`, {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: 'All models',
          pos: '',
        });
      },
      click(targetURL, label, linkValue, carlineGroup, carline, amount) {
        track(checkLinkTarget(targetURL), `lean moxx - click on '${linkValue}'`, {
          label,
          targetURL: completeTargetURL(targetURL),
          elementName: 'text link',
          value: `${carlineGroup}~${carline}`,
          pos: amount ? amount?.toString() : '',
        });
      },
      trackTagClick(label, value) {
        track('navigation', 'lean moxx - click to change tag', {
          label,
          targetURL: '',
          clickID: '',
          elementName: 'tag',
          value,
        });
      },
      trackExpandCardClick(value, id, element) {
        const direction = !selectedCarGroupId || selectedCarGroupId !== id ? 'open' : 'close';
        track('navigation', `lean moxx - click to ${direction} carline group tile`, {
          label: value,
          targetURL: '',
          clickID: '',
          elementName: element,
          value: id,
        });
      },
      trackExpandTagsClick(value: string) {
        track('navigation', 'lean moxx - click to show more tags', {
          label: value,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
        });
      },
      trackCarlineImageClick(value) {
        track('navigation', 'lean moxx - click on image', {
          elementName: 'image',
          value,
        });
      },
      trackExpandVTPLayerClick(label, value) {
        track('navigation', 'lean moxx - click vtp menu', {
          elementName: 'text link',
          label,
          value,
          pos: '',
        });
      },
      trackCTAClick(targetURL, value, label, ctaType, amount, elementName) {
        track(checkLinkTarget(targetURL), `lean moxx - click on '${ctaType}'`, {
          label,
          targetURL: completeTargetURL(targetURL),
          elementName: elementName || 'text link',
          value,
          pos: amount ? amount?.toString() : '',
        });
      },
      trackScrollOrSwipeEvent() {
        track('navigation', 'lean moxx - swipe carline group tiles', {
          elementName: 'other',
        });
      },
    };
  }, [trackingService, selectedCarGroupId]);
}
