import {
  Button,
  ButtonGroup,
  Popover,
  PopoverBody,
  PopoverTrigger,
  Text,
} from '@audi/audi-ui-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTrackingManager } from '../../context/useTrackingManager';
import { useNumberFormatter } from '../../context/Context';
import { useInitialState } from '../../context/InitialStateContext';
import { matchI18n, useI18n } from '../../i18n';

export const StyledPopoverMaxWidthContainer = styled.div`
  max-width: 430px; // as aligned with Design Team
  overflow: hidden;
`;

export interface StockCar {
  readonly url: string;
  readonly amount: number;
}

export interface VtpPopoverProps {
  readonly usedCars: StockCar;
  readonly newCars: StockCar;
  readonly carsInStock: number;
  readonly carlineGroup: string;
  readonly carline: string;
}

const VtpPopoverPopover: React.FC<VtpPopoverProps> = ({
  newCars,
  usedCars,
  carsInStock,
  carlineGroup,
  carline,
}) => {
  const { i18nMessages } = useInitialState();

  const { trackCTAClick, trackExpandVTPLayerClick } = useTrackingManager();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverTitle = useI18n('newAndUsedCarsPopoverTitle');
  const popoverDescription = useI18n('newAndUsedCarsPopoverDescriptionText');

  const newCarsMarketText = matchI18n(
    'newAndUsedCarsPopoverCTANewCarsMarketTextWithValue',
    i18nMessages,
    {
      value: useNumberFormatter(newCars.amount).toString(),
    },
  );

  const usedCarsMarketText = matchI18n(
    'newAndUsedCarsPopoverCTAUsedCarsMarketTextWithValue',
    i18nMessages,
    {
      value: useNumberFormatter(usedCars.amount).toString(),
    },
  );

  const availableCarsInStockText = matchI18n('newAndUsedCarsCTALabel', i18nMessages, {
    value: useNumberFormatter(carsInStock).toString(),
  });

  useEffect(() => {
    if (popoverOpen)
      trackExpandVTPLayerClick(
        matchI18n('newAndUsedCarsPopoverTitle', i18nMessages),
        `${carlineGroup}~${carline}`,
      );
  }, [popoverOpen]);
  return (
    <PopoverTrigger
      keepContentHeight
      placement="bottom left"
      autoFocus
      onClose={() => setPopoverOpen(false)}
    >
      {(triggerProps) => {
        return (
          <>
            <Button
              {...triggerProps}
              data-testid="newAndUsedCarsButton"
              variant="text"
              size="small"
              stretch
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //  @ts-ignore
                triggerProps.onClick();
                setPopoverOpen(!popoverOpen);
              }}
            >
              {availableCarsInStockText}
            </Button>
            <Popover aria-label={popoverTitle}>
              <PopoverBody>
                <StyledPopoverMaxWidthContainer>
                  <Text variant="copy1" spaceStackEnd="s">
                    {popoverTitle}
                  </Text>
                  <Text variant="copy2" spaceStackEnd="s">
                    {popoverDescription}
                  </Text>
                  <ButtonGroup variant="text-buttons">
                    {newCars.amount > 0 && (
                      <Button
                        href={newCars.url}
                        variant="text"
                        size="small"
                        onClick={() =>
                          trackCTAClick(
                            newCars.url,
                            `${carlineGroup}~${carline}`,
                            matchI18n('newAndUsedCarsPopoverCTANewCarsMarketText', i18nMessages),
                            'vtp new',
                            newCars.amount,
                          )
                        }
                      >
                        {newCarsMarketText}
                      </Button>
                    )}
                    {usedCars.amount > 0 && (
                      <Button
                        href={usedCars.url}
                        variant="text"
                        size="small"
                        onClick={() =>
                          trackCTAClick(
                            usedCars.url,
                            `${carlineGroup}~${carline}`,
                            matchI18n('newAndUsedCarsPopoverCTAUsedCarsMarketText', i18nMessages),
                            'vtp used',
                            usedCars.amount,
                          )
                        }
                      >
                        {usedCarsMarketText}
                      </Button>
                    )}
                  </ButtonGroup>
                </StyledPopoverMaxWidthContainer>
              </PopoverBody>
            </Popover>
          </>
        );
      }}
    </PopoverTrigger>
  );
};

export default VtpPopoverPopover;
