import styled from 'styled-components';

const CARD_MIN_WIDTH = 285;
const CARD_MAX_WIDTH = 332;
const CARD_GAP = 16;
// how to use a css var here?
const PAGE_MARGIN = {
  s: 56,
  m: 80,
  l: 120,
};

interface FoldoutProps {
  actualHeight?: number;
}

export const Foldout = styled.div<FoldoutProps>`
  height: ${(props) => (props.actualHeight ? `${props.actualHeight}px` : '0')};
  overflow: hidden;
  background-color: var(${(props) => props.theme.colors.base.grey['5']});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    transition-property: height;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
`;

export const StyledCarlineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${CARD_MIN_WIDTH}px, ${CARD_MAX_WIDTH}px));

  grid-gap: ${CARD_GAP}px;
  padding: var(${(props) => props.theme.responsive.spacing.l})
    var(${(props) => props.theme.responsive.spacing.pageMargin});

  @media (min-width: ${CARD_MIN_WIDTH * 2 + CARD_GAP + PAGE_MARGIN.s}px) {
    grid-template-columns: repeat(2, minmax(${CARD_MIN_WIDTH}px, ${CARD_MAX_WIDTH}px));
  }

  @media (min-width: ${CARD_MIN_WIDTH * 3 + CARD_GAP * 2 + PAGE_MARGIN.m}px) {
    grid-template-columns: repeat(3, minmax(${CARD_MIN_WIDTH}px, ${CARD_MAX_WIDTH}px));
  }

  @media (min-width: ${CARD_MIN_WIDTH * 4 + CARD_GAP * 3 + PAGE_MARGIN.l}px) {
    grid-template-columns: repeat(4, minmax(${CARD_MIN_WIDTH}px, ${CARD_MAX_WIDTH}px));
  }
`;
