import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { InitialState } from '../../@types/FeatureAppTypes';

interface State {
  readonly initialState: InitialState;
}

export const Context = createContext<State>({} as State);

interface InitialStateContextProviderProps {
  readonly initialState: InitialState;
}

export const InitialStateContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<InitialStateContextProviderProps>) => {
  const contextValue = useMemo(() => ({ initialState }), [initialState]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useInitialState = () => useContext(Context).initialState;
