import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { CardWrapper, StyledButton, StyledImage, StyledSliderItem } from './CarGroupStyles';
import { MoxxCarlineGroup } from '../../../@types/MoxxTypes';
import { useAppContext } from '../../context/Context';
import { ArrowIcon } from '../ArrowIcon';
import { getOptimizedImageSrc, getSizes, getSrcSet } from '../../utils/imgUtils';
import { useTrackingManager } from '../../context/useTrackingManager';
import {
  CARGROUP_IMAGE_SIZE_DESKTOP,
  CARGROUP_IMAGE_SIZE_MOBILE,
  IMAGE_MIME_TYPE,
} from '../../constants';

interface CarGroupCardProps {
  carlineGroup: MoxxCarlineGroup;
  isSelected: boolean;
  onClick: () => void;
  onFocus?: () => void;
  amountCarlines: string;
  dataTestId: string;
}

const CarGroupCard: React.ForwardRefRenderFunction<HTMLLIElement, CarGroupCardProps> = (
  { carlineGroup, isSelected, onClick, amountCarlines, dataTestId, onFocus },
  ref,
) => {
  const { selectedCarGroupId } = useAppContext();
  const cardImageSrc = getOptimizedImageSrc(carlineGroup.carlines[0].image, {
    extension: IMAGE_MIME_TYPE,
    width: '180',
  });
  const { trackExpandCardClick } = useTrackingManager();

  return (
    <CardWrapper
      key={carlineGroup.id}
      ref={ref}
      tabIndex={0}
      aria-label={carlineGroup.name}
      onFocus={onFocus}
    >
      <StyledSliderItem
        selected={isSelected}
        className={isSelected ? 'selected' : ''}
        data-testid={dataTestId}
        onClick={() => {
          onClick();
          trackExpandCardClick(carlineGroup.name, carlineGroup.id, 'image');
        }}
      >
        <Text variant="order4">{carlineGroup.name}</Text>
        <StyledImage
          src={cardImageSrc}
          alt={carlineGroup.name}
          height="100%"
          srcSet={getSrcSet(
            [
              CARGROUP_IMAGE_SIZE_DESKTOP,
              CARGROUP_IMAGE_SIZE_DESKTOP * 2,
              CARGROUP_IMAGE_SIZE_MOBILE,
              CARGROUP_IMAGE_SIZE_MOBILE * 2,
            ].map((width) => {
              return {
                src: getOptimizedImageSrc(cardImageSrc, { width }),
                width: `${width}w`,
              };
            }),
          )}
          sizes={getSizes([
            { min: 'm', width: `${CARGROUP_IMAGE_SIZE_DESKTOP}px` },
            { width: `${CARGROUP_IMAGE_SIZE_MOBILE}px` },
          ])}
        />
        <StyledButton data-testid={carlineGroup.id}>
          <Text variant="copy1">{amountCarlines}</Text>
          <ArrowIcon rotated={carlineGroup.id === selectedCarGroupId} />
        </StyledButton>
      </StyledSliderItem>
    </CardWrapper>
  );
};
export default React.forwardRef(CarGroupCard);
